import axios from 'axios'
import {getCookie} from './auth'
var API = process.env.API

export const createBlog = (form) => {
    return fetch(`${API}/blog`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: `JWT ${getCookie('token')}`,
            "Access-Control-Allow-Origin": '*'
        },
        body: form
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const createBlogSection = (data) => {
    return fetch(`${API}/section`,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `JWT ${getCookie('token')}`
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateBlogSection = (data, id) => {
    return fetch(`${API}/section/${id}`,{
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `JWT ${getCookie('token')}`
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateBlog = (form, id) => {
    return fetch(`${API}/blog/${id}`,{
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            Authorization: `JWT ${getCookie('token')}`,
            "Access-Control-Allow-Origin": '*'
        },
        body: form
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const singleBlog = slug => {
    return fetch(`${API}/blog/${slug}`, {
        method: 'GET'
    }).then((response) => {
        return response.json()
    }).catch(err => console.log(err))
}

export const lesson = id => {
    return fetch(`${API}/blogbyid/${id}`, {
        method: 'GET'
    }).then((response) => {
        return response.json()
    }).catch(err => console.log(err))
}

export const list = (skip, limit) => {
    const data = {
        skip,
        limit
    }
    return fetch(`${API}/blogs`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const listSection = () => {
    return fetch(`${API}/sections`,{
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}
export const listOneSection = (slug) => {
    return fetch(`${API}/section/${slug}`,{
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const remove = (slug) => {
    return fetch(`${API}/blog/${slug}`,{
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const removeSection = (slug) => {
    return fetch(`${API}/section/${slug}`,{
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateEvent = (modulo, lesson, estado) => {
    return fetch(`${API}/module/${modulo}/lesson/${lesson}/event`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        },
        body: JSON.stringify({'stage': estado})
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}