import axios from 'axios'
import {getCookie} from '../auth'
const API = process.env.API

export const create = (company, body) => {
    return axios.post(`${API}/onboarding/${company}`, body,
        {
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getCookie('token')
            }
        }
    )
}

export const sendWhatsapp = (userid, body) => {
    return axios.post(`${API}/user/${userid}/sendwhatsapp`, body,
        {
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getCookie('token')
            }
        }
    )
}

export const get = (company) => {
    return fetch(`${API}/onboarding/${company}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getObFromUser = () => {
    return fetch(`${API}/onboarding`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getProgressObFromUser = (user) => {
    return fetch(`${API}/onboarding/progress/${user}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const remove = (id, company) => {
    return fetch(`${API}/onboarding/${company}/${id}`,{
        method: 'delete',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const createModule = (onboardingid, body) => {
    return axios.post(`${API}/module/${onboardingid}`, body,
        {
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getCookie('token')
            }
        }
    )
}

export const deleteModule = (onboarding_id, module_id) => {
    return fetch(`${API}/module/${onboarding_id}/${module_id}`,{
        method: 'delete',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const deleteLesson = (module_id, lesson_id) => {
    return fetch(`${API}/module/${module_id}/lesson/${lesson_id}`,{
        method: 'delete',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const updateLesson = (moduleid, lessonid, values) => {
    return axios.put(`${API}/module/${moduleid}/lesson/${lessonid}`, values,
        {
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getCookie('token')
            }
        }
    )
}

export const updateModule = (onboardingid, moduleid, values) => {
    return axios.put(`${API}/module/${onboardingid}/${moduleid}`, values,
        {
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getCookie('token')
            }
        }
    )
}

export const updateLessonsSortModule = (moduleid, lessons) => {
    return axios.put(`${API}/module/${moduleid}/lesson/sort`, lessons,
        {
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getCookie('token')
            }
        }
    )
}

export const addLessonModule = (moduleid, values) => {
    return axios.post(`${API}/module/${moduleid}/lesson`, values,
        {
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getCookie('token')
            }
        }
    )
}

// Capacitaciones

export const setTrainingDate = (onboardingid, moduleid, values) => {
    return axios.post(`${API}/module/${onboardingid}/${moduleid}/training`, values,
        {
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getCookie('token')
            }
        }
    )
}

export const updateTraining = (onboardingid, moduleid, trainingid, values) => {
    return axios.put(`${API}/module/${onboardingid}/${moduleid}/training/${trainingid}`, values,
        {
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization':'JWT '+getCookie('token')
            }
        }
    )
}