import { useEffect, useState } from 'react';
import { isAuth } from '../actions/auth'

export default function ChatSoporte({focus}) {

    useEffect(() => {
        let usuario = isAuth()
        if (!usuario) return () => {}
        // console.log(usuario.group)
        let grupo = usuario.group
        if (grupo.parent) {
            grupo = grupo.ancestors[0]
        }
        if (window && usuario.intercom_token) {
            window.intercomSettings = {
                app_id: "clzsktrk",
                user_id: usuario._id,
                user_hash: usuario.intercom_token,
                name: usuario.name+' '+usuario.lastname,
                email: usuario.email,
                company: {
                    id: usuario.intercom_company_id,
                    name: usuario.intercom_company
                }
            };
            (function () { 
                var w = window; 
                var ic = w.Intercom; 
                if (typeof ic === "function") { 
                    ic('reattach_activator'); 
                    ic('update', w.intercomSettings); 
                    if (focus) ic('show');
                } else { 
                    var d = document; 
                    var i = function () { 
                        i.c(arguments); 
                    }; 
                    i.q = []; 
                    i.c = function (args) { 
                        i.q.push(args); 
                    }; 
                    w.Intercom = i; 
                    var l = function () { 
                        var s = d.createElement('script'); 
                        s.type = 'text/javascript'; 
                        s.async = true; 
                        s.src = 'https://widget.intercom.io/widget/clzsktrk'; 
                        var x = d.getElementsByTagName('script')[0]; 
                        x.parentNode.insertBefore(s, x); 
                    }; 
                    if (document.readyState === 'complete') { 
                        l(); 
                    } else if (w.attachEvent) { 
                        w.attachEvent('onload', l); 
                    } else { 
                        w.addEventListener('load', l, false); 
                    } 
                } 
            })();
        } else {
            return
        }
    }, [process.browser, process.browser && window && window.Intercom])
    return null
}