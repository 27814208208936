
import Header from '../../components/Header'
import { useEffect, useState } from 'react';
import {isAuth, setChaportId, removeCookie } from '../../actions/auth'
import Private from '../../components/Private'
import { useRouter } from 'next/router';
import { Box, typography } from '@mui/system';
import { Button, Grid, Tab, Tabs, Typography, Container, Modal, List, ListItemText, ListItemButton, Divider, ListItemIcon, ListItemAvatar, Avatar, Alert } from '@mui/material';
import { ArrowBack, ArrowRight, Drafts, MailOutline, ViewAgenda } from '@mui/icons-material';
import {view, get} from '../../actions/notification'
import Link from '../core/NextLinkWraped'
import DOMPurify from 'dompurify'

export default function Notifications({open, setOpen}) {

  let [user,setUser] = useState({})
  let [notificacion,setNotificacion] = useState({})
  let [error,setError] = useState(false)
  let [loading,setLoading] = useState(true)

  let router = useRouter()
  useEffect(() => {
    setUser(isAuth())
    setLoading(true)
    if(!open) return () => {}
    get(open).then(noti => {
        if(!noti) return setError('No se encontro la notificacion que estas buscando')
        setError()
        setNotificacion(noti)
        setLoading(false)
    })
  },[open])

  var handleClose = () => {
      view(open).then(() => {
        setOpen()
      })
  }

  var handleOut = () => {
    view(open).then(() => {
        router.push(`/notifications/${_id}`)
    })
  }

  var {title, body, _id} = notificacion

  return (
        <Modal open={!!open} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={{
                maxWidth: '750px', 
                maxHeight:'600px', 
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                background: '#f7f9fb',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,}}>
                    {!loading ? <Box sx={{
                        width: '100%',
                        height: '500px',
                        overflowY: 'auto'
                    }}>
                <Typography variant="h3" component={"h1"} sx={{mt: 2}} >{title}</Typography>
                <Divider sx={{width: '100%'}}/>
                <Box sx={{p:2, width: '100%', maxWidth: '100%'}} className="img-blog">
                    {notificacion && notificacion.body && <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(notificacion.body.replaceAll('class="ql-video"','class="ql-video" style="min-width: 560px" height="315"').replaceAll('?showinfo=0','?showinfo=0&rel=0'), { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })}}></div>}
                </Box>
                </Box> : ''}
                {!loading && 
                <Grid container justifyContent={'right'} sx={{mt:2}}>
                    <Button  sx={{mr: 1}} onClick={handleOut}>Ver</Button>
                    <Button variant='contained' sx={{ml: 1}} endIcon={<ArrowRight/>} onClick={handleClose}>Continuar</Button>
                </Grid>}
          </Box>
        </Modal>
  )
}
