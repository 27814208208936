import React, { useState, useEffect } from "react";
import NextLn from "./core/NextLinkWraped";
import {
  isAuth,
  signout,
  isGhostAdmin,
  changeToGhostAdmin,
  getCookie,
  getCompany,
} from "../actions/auth";
import { getObFromUser } from "../actions/comercial/onboarding";
import { useRouter } from "next/router";
import {
    AppBar,
    Box,
    Badge,
    Modal,
    Toolbar,
    Menu,
    MenuItem,
    Button,
    Typography,
    Grid,
    Avatar,
    Drawer,
    CssBaseline,
    List,
    Divider,
    ListItem,
    Tooltip,
    Link,
    CardMedia,
} from "@mui/material";
import { styled } from "@mui/system";

import {
    KeyboardArrowDown,
    AccountBox,
    Logout,
    Info,
    AdminPanelSettings,
    Home,
    PointOfSale,
    Assignment,
    ListAlt,
    Send,
    Leaderboard,
    People,
    Sell,
    ChevronRight,
    School,
    MeetingRoom,
    Contacts,
    MenuBook,
    ChatBubble,
    Business,
    ModelTraining
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChatSoporte from "../components/ChatSoporte";
import Module from "../components/academy/Module"
import { connect } from "react-redux";
import { setMenuOpen, logOutAPP } from "../redux/actions/main";
import NotificationModal from "./notification/NotificationModal";
import { list as getListNotification } from "../actions/notification";
import moment from "moment";
import "moment/locale/es";
import _ from "lodash";

import { isFeatureEnabled, Feature } from "../helpers/featureFlagHelpers";

moment.locale("es");

const drawerWidth = 58;

function Header({ children, logOutAPP, setOpenMenu, setMenuOpen, openMenu, bg = '', chat = true }) {
    const router = useRouter()
    const [list, setList] = useState([])
    const [user, setUser] = useState(false)
    const [group, setGroup] = useState(false)
    const [academyModal, setAcademyModal] = useState(false)
    const [notiModal, setNotiModal] = useState()
    const [notiList, setNotiList] = useState()
    const [onboarding, setOnboarding] = useState(false);
    const [focusChat, setFocusChat] = useState(router.asPath.split("?")[1] == 'soporte' || false)
    let [notificaciones, setNotificaciones] = useState();

  useEffect(() => {
    let user = isAuth();
    setUser(user);
    if (!user) return () => {};
    let group = user.group;
    if (group.parent) {
      group = group.ancestors[0];
    }
    let nav = group.nav;
    setGroup(group);
    setList(
      nav.filter(
        (n) =>
          user.roles.filter((r) => {
            return n.role.indexOf(r) > -1;
          }).length > 0
      )
    );
    if (user) {
      getListNotification().then((lista) => {
        if (!lista || !lista.sort) return "";
        setNotificaciones(
          lista
            .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
            .filter((e) => !e.viewed)
            .reverse()
        );
        let notis = lista.filter((n) => n.popup && !n.viewed);
        if (notis.length) {
          setNotiModal(notis[0]._id);
        }
      });
    }
  }, []);

  var NextLink = (props) => <NextLn {...props} />;

  ///// MENU MUI
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState(null);
  const [logo, setLogo] = useState("/logo.png");
  const open = Boolean(anchorEl);
  const openNotifications = Boolean(notifications);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (focusChat) setFocusChat(false)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotifications = (event) => {
    setNotifications(event.currentTarget);
  };
  const handleCloseNotifications = () => {
    setNotifications(null);
  };
  const NavText = styled(Typography)(`
        text-transform: none;
    `);
  const ListItemLateral = styled(ListItem)(`
        height: 57px;
    `);

  function stringAvatar(nombre) {
    return {
      sx: {
        bgcolor: `#0080ff`,
        color: `#e3f2fd`,
      },
      children: `${nombre.split(" ")[0][0] ?? ""}${nombre.split(" ")[1][0] ?? ""}`,
    };
  }

  function NextMenuButton({ href, title, icon, onClick }) {
    let page = router.asPath.split("/")[1];
    let jailUrl = _.get(isAuth(), "jailUrl", []);
    if (jailUrl.length > 0) {
      let valid_jail = false;
      let path_name = href.split("/");

            jailUrl.map((url_jail) => {
                let urlNew = url_jail.split('/')
                let valid_individiual = true
                
                if(urlNew[1] == path_name[1]) valid_jail = true
            })
            if(!valid_jail){
                return <></>
            }
        }
        return (
            <NextLink href={href} onClick={onClick} style={{ textDecoration: 'none', }}>
                <Tooltip title={title} placement="right">
                    <ListItemLateral button sx={(page == href.split('/')[1]) ? {backgroundColor: '#fff', color: '#0080ff', '&:hover': {backgroundColor: '#fff', color: '#006DF9'}} : { color: '#E3F2FD', '&:hover': {backgroundColor: '#027BF5' }}}>
                        {icon}
                    </ListItemLateral>
                </Tooltip>
            </NextLink>
        )
    }

    const unfocusChat = (e) => {
        e.target.style.display = 'none';
        if (window && window.Intercom) window.Intercom('hide')
    }

    useEffect(() => {
        if(getCompany()?.crmConfig?.forceUseOfCanary && getCookie("onApp") !== "true" && window) {
            let url = window.location.href.replace('https://panel.getventia.com', 'https://canary.getventia.com')
            
            if(url != window.location.href) {
                window.location.replace(url)
            }

        }
    }, [user])

  ///// FIN MENU

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <NotificationModal open={notiModal} setOpen={setNotiModal} />
            {chat ? <ChatSoporte focus={focusChat} /> : (() => {
                window && window.Intercom && window.Intercom('shutdown')
            })()}
            {focusChat && <Box position="fixed" sx={{top: 0, left: 0, backgroundColor: '#000', width: 1, height: 1, zIndex: 3, opacity: 0.5}} onClick={unfocusChat}/>}
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ width: `100%`, ml: `${drawerWidth}px`, zIndex: 2, boxShadow: 'none', borderBottom: '1px solid #f6f6f6' }}
            >
                <Toolbar sx={{ bgcolor: 'white', height: '64px' }}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={() => {
                            if (setOpenMenu) setOpenMenu(!openMenu)
                        }}
                        edge="start"
                        sx={{ marginRight: '10px', color: '#0080ff', display: { xs: 'flex', sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <CardMedia
                        component='img'
                        sx={{ objectFit: 'contain', width: 'auto', height: '26px', display: { xs: 'flex', sm: 'none' } }}
                        image='/logo-min.png'
                        alt='Logo de Ventia'
                    />
                    <CardMedia
                        component='img'
                        sx={{ objectFit: 'contain', width: 'auto', height: '26px', display: { xs: 'none', sm: 'flex' } }}
                        image='/logo.png'
                        alt='Logo de Ventia'
                    />
                    <Box
                        sx={{
                            ml: 'auto',
                            color: '#0080ff'
                        }}
                    >
                        <Button
                            sx={{
                                ml: 'auto',
                                color: '#217af4'
                            }}
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDown />}
                        >
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                            >
                                <Avatar xs="auto" alt="Avatar" {...stringAvatar( user ? user.name + " " + user.lastname : '  ')} />
                                <Grid
                                    item
                                    ml={1}
                                    container
                                    direction="column"
                                    alignItems="flex-start"
                                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                                    xs="auto"
                                >
                                    <NavText variant="subtitle1" fontSize={14}>{user.name} {user.lastname}</NavText>
                                    <NavText variant="caption" noWrap display="block">{group ? group.displayName : ''}</NavText>
                                </Grid>
                            </Grid>
                        </Button>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <NextLn href="/settings">
                                <MenuItem sx={{ py: 1.5, px: 5 }} onClick={handleClose}>
                                    <AccountBox />
                                    <Typography variant="subtitle2" ml={2}>Mi perfil</Typography>
                                </MenuItem>
                            </NextLn>
                            {(!getCookie('onApp') || getCookie('onApp') != 'true') &&
                            <MenuItem sx={{ py: 1.5, px: 5 }} onClick={() => {
                                logOutAPP(null)
                                signout(() => window.location.replace('/login'))
                            }}>
                                <Logout />
                                <Typography variant="subtitle2" ml={2}>Cerrar sesión</Typography>
                            </MenuItem>
                        }
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    zIndex: 1,
                    width: {xs: (openMenu ? drawerWidth : 0), sm: drawerWidth},
                    transition: 'width .5s',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: {xs: (openMenu ? drawerWidth : 0), sm: drawerWidth},
                        transition: 'width .5s',
                        boxSizing: 'border-box',
                        overflowX: 'clip',
                        border: '0px',
                    },
                    boxShadow: '0 0 21px 0 rgb(89 102 122 / 10%)'
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar />
                <Divider />
                <List sx={{
                    py: 0,
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: '#0080ff',
                    color: '#e3f2fd'
                }}>
                    <NextMenuButton href="/" title="Inicio" icon={<Home />}/>
                    {user.productType == 'ventia_freelancer' || _.get(isAuth(), 'jailUrl', []).length > 0 ? (
                        <>
                            <NextMenuButton href="/contact" title="CRM" icon={<ChatBubble />}/>
                            <NextMenuButton href="/contacts" title="Contactos" icon={<People />}/>
                        </>
                    ) : (
                        <Link sx={{ color: '#fff!important' }} href="https://web.sirena.app/agent/shared/user/" target="_blank">
                            <Tooltip title="CRM" placement="right">
                                <ListItemLateral button>
                                    <ChatBubble />
                                </ListItemLateral>
                            </Tooltip>
                        </Link>
                    )}
                    <NextMenuButton href="/reportes/prospect" title="Reportes" icon={<ListAlt />} onClick={() => {if (setOpenMenu) setOpenMenu(true)}}/>
                    {group && group.sale && group.sale.presale ? (
                        <NextMenuButton href="/presale" title="Crear PreVenta" icon={<Assignment />}/>
                    ) : ''}
                    {user && user.roles.indexOf('comercial') >= 0 ? (
                        <NextMenuButton href="/comercial/sale" title="Ventas" icon={<Sell />}/>
                    ) : ''}
                    {user && user.roles.indexOf('comercial') >= 0 ? (
                        <NextMenuButton href="/retention" title="Envios masivos" icon={<Send />}/>
                    ) : ''}
                    <Box mt={"auto"}>
                        {user && user.roles.indexOf("admin") >= 0 ? (
                            <NextMenuButton href="/grupos" title="Empresas" icon={<AdminPanelSettings />}/>
                        ) : (
                            ""
                        )}
                        {user && isGhostAdmin() ? (
                            <Tooltip title="Volver a admin" placement="right">
                                <ListItemLateral button onClick={() => changeToGhostAdmin(() => router.reload())}>
                                    <MeetingRoom />
                                </ListItemLateral>
                            </Tooltip>
                        ) : (
                            ""
                        )}
                        {
                            user && (user.roles.indexOf("super") >= 0 || user.roles.indexOf("comercial") >= 0) && <NextMenuButton href="/company" title="Mi empresa" icon={<Business />}/>
                        }
                    </Box>
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default" }}>
                <Box sx={{ display: "flex" }}>
                    <Box
                        width="100%"
                        sx={{
                            minHeight: bg != '' ? "calc(100vh - 64px)" : "",
                            backgroundColor: bg ?? "",
                            mt: '64px'
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            </Box>
            <Modal
                open={academyModal}
                onClose={() => setAcademyModal(false)}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1,
                    maxWidth: 800,
                    bgcolor: 'background.paper',
                    borderRadius: 10,
                    boxShadow: 24,
                }}>
                    <Module progress={0} closeFunction={() => setAcademyModal(false)}/>
                </Box>
            </Modal>
        </Box>
  );
}

const mapStateToProps = (state) => ({
  openMenu: state.main.open,
});

const mapDispatchToProps = {
  setOpenMenu: setMenuOpen,
  logOutAPP: logOutAPP,
};

Header.defaultProps = {
  setMenuOpen: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
