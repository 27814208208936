import React, { useState } from "react";
import {
    Box,
    Theme,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Chip,
} from "@mui/material";
import { useRouter } from "next/router";
import moment from "moment";
import { SxProps } from "@mui/system";

moment.locale();

interface Module {
    title?: string;
    sx?: SxProps<Theme>;
    sxCardContent?: SxProps<Theme>;
    children?: React.ReactNode;
    key?: string;
    action?: React.ReactNode;
    counter?: string | number | boolean;
    counterColor?:
    | "default"
    | "info"
    | "primary"
    | "secondary"
    | "error"
    | "success"
    | "warning";
    id?: string;
    titleElement?: JSX.Element;
}

function Module({
    title,
    titleElement,
    sx,
    sxCardContent,
    children,
    key,
    action,
    counter,
    counterColor = "info",
    id,
}: Module) {
    var router = useRouter();

    return (
        <Card
            sx={{
                boxShadow: "0 0 20px rgb(8 21 66 / 5%)",
                borderRadius: "10px !important",
                mt: 0,
                ...sx,
            }}
            key={key}
            id={id}
        >
            {title || !!titleElement ? (
                <CardHeader
                    title={
                        <>
                            <Typography variant="h6">
                                {title}
                                {titleElement ?? <></>}
                                {counter && (
                                    <Chip
                                        size="small"
                                        sx={{ ml: 1 }}
                                        color={counterColor}
                                        label={counter.toString()}
                                    />
                                )}
                            </Typography>
                        </>
                    }
                    action={action}
                    sx={{
                        borderBottom: "1px solid #ecf3fa",
                        py: 1,
                        px: !!titleElement ? 1 : 3,
                        ".MuiCardHeader-action": {
                            alignSelf: "center",
                        },
                    }}
                />
            ) : (
                ""
            )}
            <CardContent sx={{ pt: 0.75, ...sxCardContent }}>{children}</CardContent>
        </Card>
    );
}

export default Module;