import { Loader2 } from "lucide-react"

const Loading = () => {
    return <div className="justify-center flex items-center">
        <div>
            <Loader2 className="mr-2 h-10 w-10 animate-spin text-primary text-3xl" />
        </div>
    </div>
}

export default Loading