import { get } from "lodash";
import { getCompany, isAuth } from "../actions/auth";

export const isFeatureEnabled = (feature: Feature): boolean => {
  return get(getCompany(), `crmConfig.${feature}`, false);
};

export const isPersonalFeatureEnables = (feature: PersonalFeature):boolean => {
  return get(isAuth(), `config.${feature}`, false)
}

export enum PersonalFeature {
  PERSONAL_EMAIL = "enablePersonalEmail",
  PERSONAL_WHATSAPP = "enablePersonalWhatsapp",
  PERSONAL_MAP = "mapEnabled"
}


export enum Feature {
  CLOSE_SALES = "closeSaleEnabled",
  CHANNELS = "enableChannels",
  UNOFFICAL_WHATSAPP = "enableUnofficalWhatsapp",
  LOCATION_FIELD = "enableLocationField",
  LOCATION_REQUIRED = "locationFieldObligatory",
  INBOX = "enableInbox",
  SOCIAL_ICONS ="enableSocialIcons",
  DISABLE_LASTNAME = "disableLastName",
  DISABLE_EMAIL = "disableEmail",
  DUPLICATE_PHONES_GROUP = "enableDuplicatePhonesOnGroup",
  DISABLE_WHATSAPP_LINK = "disableWhatsapp",
  PERSONAL_WHATSAPP_CREATE_CONTACT = "personalWhatsappCreateContact",
  ENABLE_BUSINESS_SALES_MODEL = "enableBusinessSalesModel",
}
