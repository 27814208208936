import fetch from 'isomorphic-fetch'
import {getCookie} from './auth'
var API = process.env.API

export const list = () => {
    return fetch(`${API}/notification`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const listType = (type) => {
    return fetch(`${API}/notification/type/${type}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const listByGroup = () => {
    return fetch(`${API}/notification/group`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const getContactNotification = () => {
    return fetch(`${API}/notification/contact`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const watchContactNotification = (id) => {
    return fetch(`${API}/notification/contact/seen/${id}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const remove = (type) => {
    return fetch(`${API}/notification/remove/${type}`,{
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const removeComercial = (id) => {
    return fetch(`${API}/notification/removecomercial/${id}`,{
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const get = (id) => {
    return fetch(`${API}/notification/${id}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const view = (id) => {
    return fetch(`${API}/notification/view/${id}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getAdmin = (id) => {
    return fetch(`${API}/notification/get/${id}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const getComercial = (id) => {
    return fetch(`${API}/notification/getcomercial/${id}`,{
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        }
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const sendMasive = (body) => {
    return fetch(`${API}/notification/masivesend`,{
        method: 'post',
        headers: {
            Accept: 'application/json',
            Authorization: `JWT ${getCookie('token')}`,
            "Access-Control-Allow-Origin": '*'
        },
        body
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const sendCompany = (body) => {
    return fetch(`${API}/notification/companysend`,{
        method: 'post',
        headers: {
            Accept: 'application/json',
            Authorization: `JWT ${getCookie('token')}`,
            "Access-Control-Allow-Origin": '*'
        },
        body
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}

export const sendMasiveGroup = (body, group) => {
    return fetch(`${API}/notification/masivesendgroup/${group}`,{
        method: 'post',
        headers: {
            Accept: 'application/json',
            Authorization: `JWT ${getCookie('token')}`,
            "Access-Control-Allow-Origin": '*'
        },
        body
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}