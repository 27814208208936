import NextLink from 'next/link'

const NextLinkWraped = ({ href, children, ...props }) => {
    
    return (
        <NextLink href={href} style={{textDecoration: 'none'}} {...props} >
            {children}
        </NextLink>
    )
}

export default NextLinkWraped