import * as React from 'react';
import { useEffect, useState } from 'react'; 
import PropTypes from 'prop-types';
import Header from '../../components/Header'
import Private from '../../components/Private'
import Module from '../core/Module'
import { Alert, Tooltip, Box, Typography, Stepper, Step, StepLabel, Accordion, AccordionSummary, AccordionDetails, ListItemButton, ListItemIcon, ListItemText, Modal, Button, IconButton, Link, Grid, CardMedia } from '@mui/material'
import { Close, ExpandMore, ArrowForwardIos, Lock, Check, Visibility, CheckCircle, Call, ChevronRight, Moving, Person, School, Info, List, ModelTraining, Inventory, RecordVoiceOver, AccessAlarm, Archive, SwapHoriz, Smartphone, Phone, People, Flag, Sell, ListAlt, Home, KeyboardArrowRight, ArrowForward } from '@mui/icons-material'
import {getObFromUser, getProgressObFromUser} from '../../actions/comercial/onboarding'
import { isAuth } from '../../actions/auth'
import NextLink from 'next/link'
import {lesson} from '../../actions/blog'
import {getActiveUsers} from '../../actions/group'
import LoadingFullScreen from '../../components/LoadingFullScreen'
import moment from 'moment'

const modules = [
    {title: '¡Bienvenido a VENTIA!', icon: <School fontSize='large' sx={{color: '#007bff'}}/>, content: 'VENTIA será tu nueva herramienta de trabajo para manejar a tus clientes, en la Academia te mostraremos una serie de videos para aprender a utilizar la plataforma de manera rápida y fácil.'},
    {title: 'Metodología de uso', icon: <ModelTraining fontSize='large' sx={{color: '#007bff'}}/>, embedUrl: 'https://youtube.com/embed/gF2vpP-1Vm8', content: 'Nuestro sistema se basa en una metodología donde la gestión y el seguimiento del contacto es importante para clasificarlos según su estado y decisión. Aprende sobre cómo trabajar tu agenda día a día adaptado al sistema.'},
    {title: 'Ingresar y modificar contactos', icon: <People fontSize='large' sx={{color: '#007bff'}}/>, embedUrl: 'https://youtube.com/embed/EqxsNHaV7To', content: 'Cada interesado en obtener tu producto o contratar tu servicio, lo ingresaras y gestionaras a través de VENTIA. Aprende sobre cómo hacerlo.', msg: 'Para continuar, crea un contacto en tu agenda de VENTIA'},
    {title: 'Registrar seguimiento', icon: <RecordVoiceOver fontSize='large' sx={{color: '#007bff'}}/>, embedUrl: 'https://youtube.com/embed/j-qsMOGzAYU', content: 'Los seguimientos son las acciones que realizas a tu cliente para comunicarlo. Cada vez que progreses con tu cliente, lo anotarás en la ficha de tu contacto dentro de la plataforma. Aprende sobre cómo registrarlos en VENTIA de manera correcta.', msg: 'Para continuar, registra un seguimiento en un contacto de tu agenda'},
    {title: 'Agendar recordatorios', icon: <AccessAlarm fontSize='large' sx={{color: '#007bff'}}/>, embedUrl: 'https://youtube.com/embed/Y_tRIlMkt68', content: 'Los recordatorios serán tu mejor aliado, utilizandolos te mantendrán al tanto sobre cuando volver a contactar en tiempo y forma a tus clientes. Aprende sobre cómo agendarlos.', msg: 'Para continuar, agenda un recordatorio en un contacto de tu agenda'},
    {title: 'Archivar contactos', icon: <Inventory fontSize='large' sx={{color: '#007bff'}}/>, embedUrl: 'https://youtube.com/embed/Nhhie36tZR4', content: 'Una vez que finalices el contacto con tu cliente, lo archivarás según su decisión o situación final. Aprende sobre como hacerlo y como trabajarás tu cliente a futuro.', msg: 'Para continuar, archiva un contacto de tu agenda'},
    {title: 'Registro de llamadas', icon: <Call fontSize='large' sx={{color: '#007bff'}}/>, embedUrl: 'https://youtube.com/embed/ugL5NntX_Ko', content: 'Cuando utilizamos las llamadas como forma de seguir al cliente, para llevar un control real de la cantidad de veces que te comunicas con el mismo, debes mantener tu historial actualizado en VENTIA.'}
]

export default function AddModal({progress, closeFunction, listAll = false}) {
    var nextStep = () => {

    }
    const ModuleItem = ({myprogress, show = myprogress}) => {
        const module = show ? modules[show] : modules[myprogress]
        return (            
            <Box px={listAll ? 2 : 1} pt={2} display="flex" flexDirection="column" justifyContent="space-between" sx={{borderRadius: '10px', backgroundColor: '#fff'}}>
                <Grid container pb={2} my="auto" flexWrap="noWrap" alignItems="center">
                    <Box p={2} mr={2} borderRadius={'10px'} sx={{backgroundColor: '#ddeeff'}}>
                        {module.icon}
                    </Box>
                    <Typography variant="h6" fontSize={'1.1rem'}>{module.title}</Typography>
                    {myprogress < show && <Lock fontSize='medium' sx={{ml: 'auto', mr: 2}}/>}
                </Grid>
                {myprogress >= show && <Grid container spacing={2} mb={2}>
                    {module.embedUrl && (<Grid item xs={12} md={6}>
                        <CardMedia component='iframe' title={module.title} height='300' sx={{border: 0}} src={module.embedUrl}/>
                    </Grid>)}
                    <Grid item xs={12} md={module.embedUrl ? 6 : 12}>
                        <Typography variant="title">{module.content}</Typography>
                    </Grid>
                </Grid>}
                {!listAll && (<Box display={'flex'} alignItems={'center'}>
                    <Typography variant="subtitle1" sx={{ml: 1, fontSize: 13, color: '#7F8487'}}>{modules[progress].msg}</Typography>
                    {
                        progress <= 5 ? (
                            <Button disabled={progress >= 2 ? true : false} onClick={nextStep} endIcon={<ArrowForward/>} sx={{ml: 'auto', px: 3}}>
                                <Typography variant="subtitle1">Avanzar</Typography>
                            </Button>
                        ) : (
                            <Button endIcon={<ArrowForward/>} sx={{ml: 'auto', px: 3}}>
                                <Typography variant="subtitle1">Finalizar</Typography>
                            </Button>
                        )
                    }
                </Box>)}
            </Box>
        )
    }
    return (
        listAll ? (
            <Grid container my={1} spacing={2}>
                {modules.map((m, i) => <Grid item xs={12}><ModuleItem show={i} myprogress={progress}/></Grid>)}
            </Grid>
        ) : (
            <Module
                title="Academia"
                action={
                    <Box>
                        <Tooltip title="Ver todo">
                            <NextLink href="/academy">
                                <IconButton aria-label="delete" size="medium">
                                    <List />
                                </IconButton>
                            </NextLink>
                        </Tooltip>
                        <IconButton aria-label="delete" size="medium" onClick={closeFunction}>
                            <Close />
                        </IconButton>
                    </Box>
                }
            >
                <ModuleItem myprogress={progress}/>   
            </Module>
        )
    )
}