import * as t from "../types";

export const setInfo = (name) => ({
  type: t.SET_NAME,
  payload: name,
});

export const setMenuOpen = (value) => ({
  type: t.SET_MENU_OPEN,
  payload: value,
});

export const setUsers = (value) => ({
  type: t.SET_INDEX_USERS,
  payload: value,
});

export const setUsersGroup = (value) => ({
  type: t.SET_USERS_GROUP,
  payload: value,
});

export const updateColorsUsersGroup = (value) => ({
  type: t.UPDATE_COLORS_USERS_GROUP,
  payload: value,
});

export const setSelectedUser = (value) => ({
  type: t.SET_SELECTED_USER,
  payload: value,
});

export const setSelectedGroup = (value) => ({
  type: t.SET_SELECTED_GROUP,
  payload: value,
});

export const setUsarAyer = (value) => ({
  type: t.SET_USAR_AYER,
  payload: value,
});

export const logOutAPP = (value) => ({
  type: t.LOGOUT,
  payload: value,
});
